export enum VendorCode {
  Unknown = 'unknown',
  AllBet = 'ab',
  AsiaGamingCasino = 'agc',
  XINGamingSlot = 'xin',
  Redirect_Evolution = 'reevo',
  EvolutionCasino = 'evc',
  EvolutionRedTiger = 'evrt',
  EvolutionNetEnt = 'evne',
  EvolutionBigTimeGaming = 'evbtg',
  EvolutionNoLimitCity = 'evnlc',
  Timeless_Evolution = 'tlevo',
  SDiamond_Evolution = 'sdevo',
  LiveBar_Evolution = 'lvevo',
  LiveBar_DreamGaming = 'lvdg',
  HonorLink_Evolution = 'hlevo',
  HonorLink_KSlot3_Evolution = 'h3evo',
  FakeHonorQA_Evolution = 'fhevo',
  FakeHonorSocket_Evolution = 'fhsvo',
  FakeBlue_Evolution = 'fhsvo_blue',
  FakeGreen_Evolution = 'fhsvo_green',
  FakeLemon_Evolution = 'fakelemon',
  FakeTomato_Evolution = 'faketomato',
  FakeBlueGreen_Evolution = 'fakebg',
  FakeGold_Evolution = 'fakegold',
  FakeSilver_Evolution = 'fakesilver',
  FakeGrape_Evolution = 'fakegrape',
  FakeApple_Evolution = 'fakeapple',
  FakeCX_Evolution = 'fcevo',
  FakeAlpha_Cider_Evolution = 'facev',
  FakeUnionGame_Cool_Evolution = 'fucev',
  FakeChoi_Evolution = 'fchev',
  Choi_Evolution = 'chev',
  Jin_Evolution = 'jinev',
  Kroad_Evolution = 'krevo',
  CX_Star_Evolution = 'csevo',
  Alpha_Cider_Evolution = 'alcev',
  UnionGame_Cool_Evolution = 'ugcev',
  SkyGaming_Evolution = 'sgevo',
  Zenith_DreamGaming = 'dg',
  JJ_DreamGaming = 'jjdg',
  CX = 'cx',
  CX_PP = 'cxpp',
  CX_EVOLUTION = 'cxevc',
  CX_WAZDAN = 'cxwzd',
  CX_RELAX_GAMING = 'cxrxg',
  CX_GAMEART = 'cxgaa',
  CX_BLUEPRINT_GAMING = 'cxbpg',
  CX_THUNDER_KICK = 'cxtkk',
  CX_NOLIMIT_CITY = 'cxnlc',
  CX_MOBILOTS = 'cxmbl',
  CX_PLAY_PEARLS = 'cxppl',
  CX_1X2_GAMING = 'cx1x2',
  CX_ELK_STUDIOS = 'cxelk',
  CX_BOTA = 'cxbota',
  OrientalGaming = 'og',
  Wanmei = 'wm',
  SexyCasino = 'sexyc',
  SexySlot = 'sexys',
  Bota = 'bota',
  BotaKhan = 'botakhan',
  MegaHoldem = 'megah',
  LiveBar = 'lb',
  Dowinn = 'dwn',
  Univerge = 'uig',
  PragmaticPlayCasino = 'ppc',
  PragmaticPlaySlot = 'pps',
  HabaneroSlot = 'hbs',
  Booongo = 'bng',
  Booongo_Zenith = 'bngzf',
  Booongo_Pluto = 'bng',
  Playson = 'pls',
  Playson_Zenith = 'plszf',
  Playson_Pluto = 'pls',
  CG = 'cgs',
  PlaynGO = 'png',
  BTI = 'bti',
  DragoonSoft = 'dss',
  WorldEntertainment = 'wec',
  CQ9_Motivation = 'cq9c',
  CQ9_Yaxing = 'cq9a',
  CQ9_Slot = 'cq9s',
  MicroGamingCasino = 'mgc',
  MicroGamingSlot = 'mgs',
  BlackMamba_MicroGamingCasino = 'bmmgc',
  BlackMamba_MicroGamingSlot = 'bmmgs',
  SpiniX = 'spinix',
  Yggdrasil = 'ygg',
  NagaGames = 'naga',
  TopTrendGaming = 'ttg',
  LGDGaming = 'lgdg',
  BigGamingCasino = 'bgc',
  Endorphina = 'enas',
  QueenMaker = 'qms',
  PGSoft = 'pgs',
  Betixon = 'btx',
  PlayStar = 'pss',
  SmartSoft = 'ssg',
  GeminiGaming = 'gemg',
  PopOkSlot = 'popok',
  PopOkCasino = 'popokc',
  VivoGaming = 'vivo',
  GoldLion = 'gldl',
  RealGates = 'rlgt',
  DhSoft = 'dhs',
  TrippleA_RedPlanet = 'trpa',
  TrippleA_Damoa = 'trpd',
  TrippleA_Hero = 'trph',
  TrippleA_Natural = 'trpn',
  TrippleA_Plus = 'trpp',
  TrippleA_Star = 'trps',
  Grand_MicroGamingCasino = 'grmgc',
  Grand_MicroGamingSlot = 'grmgs',
  Grand_DragoonSoft = 'grdss',
  Grand_Live22 = 'grl22',
  Test = 'test',
}
